import React from "react";
import { Button, Icon } from "semantic-ui-react";

function Automate5g(props) {
	return (
		<div className="automate5g-wrapper">
			<div className="automate5g-content-wrapper">
				<div style={{ textAlign: "right" }}>
					<Button
						circular
						icon
						onClick={() => props.history.push("/")}
					>
						<Icon name="close" />
					</Button>
				</div>
				<div className="automate5g-title">
					<h1>AUTOMATING 5G ACCESS DEPLOYMENTS</h1>
				</div>
				<div className="automate5g-content">
					<div className="content-block">
    <iframe title="5g" src="https://drive.google.com/file/d/187hDAydG3GcGsIrGzrW5hLK5izpC5QTq/preview" width="100%" height="800px"/>
					</div>
				</div>
			</div>
			<div className="automate5g-bg" />
		</div>
	);
}
export default Automate5g;