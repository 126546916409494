import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Eula from "./eula/Eula";
import Solution from "./solution/Solution";
import Cable from "./cable/Cable"
import b2b from "./b2b/b2b"
import Automate5g from "./automate5g/Automate5g";
import Company from "./about/Company";
import ContactUs from "./about/ContactUs";
import PrivacyPolicy from "./about/PrivacyPolicy";
import { AuthProvider } from "./auth/AuthProvider";
import ForgotPassword from "./auth/ForgotPassword";
import Login from "./auth/Login";
import PrivateRoute from "./auth/PrivateRoute";
import Profile from "./auth/Profile";
import Register from "./auth/Register";
import Careers from "./careers/Careers";
import Header from "./common/Header";
import { MobileProvider } from "./common/MobileProvider";
import Homepage from "./homepage/Homepage";
import "./less/global.less";

function App() {
  return (
    <MobileProvider>
      <AuthProvider>
        <BrowserRouter>
          <Header />
          <Switch>
            <PrivateRoute path="/profile" component={Profile} />
            <Route exact path="/" component={Homepage} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/careers" component={Careers} />
            <Route path="/company" component={Company} />
            <Route path="/contact" component={ContactUs} />
            <Route path="/eula" component={Eula} />
            <Route path="/solution" component={Solution} />
            <Route path="/cable" component={Cable} />
            <Route path="/b2b" component={b2b} />
            <Route path="/automate5g" component={Automate5g} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/reset-password" component={ForgotPassword} />
            <Route exact path="/webinar" render={() => (window.location = "https://www.frinx.io")} />
          </Switch>
        </BrowserRouter>
      </AuthProvider>
    </MobileProvider>
  );
}
export default App;
