import React from "react";
import { Button, Icon } from "semantic-ui-react";

function Eula(props) {
	return (
		<div className="eula-wrapper">
			<div className="eula-content-wrapper">
				<div style={{ textAlign: "right" }}>
					<Button
						circular
						icon
						onClick={() => props.history.push("/")}
					>
						<Icon name="close" />
					</Button>
				</div>
				<div className="eula-title">
					<h1>End User License Agreement</h1>
				</div>
				<div className="eula-content">
					<div className="content-block">
    <iframe src="EULA_UniConfig_20200622v104.pdf" width="100%" height="800px"/>
					</div>
					<div className="content-block">

						<p>
						  <h2>Software licensed under FRINX EULA (last updated 6/22/2020)</h2>
							<ul type="square">
   					 		 <li>uniconfig</li>
    				 		 <li>translation-unit-commons </li>
    				 		 <li>unitopo</li>
    				 		 <li>cli</li>
    				 		 <li>uniconfig-native</li>
    				 		 <li>uniconfig-native-cli</li>
							</ul>
							<h2>Open Source software with FRINX modifications (send requests to licenserequests@frinx.io) (last updated 6/22/2020)</h2>
							<br/>
							<h3>Eclipse v1</h3>
							<ul type="square">
   					 		 <li>frinx-odlparent</li>
    				 		 <li>frinx-yangtools</li>
    				 		 <li>frinx-controller</li>
    				 		 <li>frinx-mdsal</li>
    				 		 <li>frinx-netconf</li>
    				 		 <li>frinx-aaa</li>
    				 		 <li>frinx-daexim</li>
    				 		 <li>distribution</li>
							</ul>
							<br/>
							<h3>Apache v2</h3>
							<ul type="square">
   					 		 <li>honeycomb</li>
    				 		 <li>openconfig</li>
    				 		 <li>uniconfig-java-client</li>
    				 		 <li>uniconfig-cli-tool (CLI operator tool for UniConfig)</li>
    				 		 <li>unitopo-units</li>
    				 		 <li>cli-units</li>
							</ul>
						</p>
					</div>
				</div>
			</div>
			<div className="eula-bg" />
		</div>
	);
}
export default Eula;