import React from "react";
import { Button, Icon } from "semantic-ui-react";

function PrivacyPolicy(props) {
  return (
    <div className="aboutus-wrapper">
      <div className="aboutus-content-wrapper">
        <div style={{ textAlign: "right" }}>
          <Button circular icon onClick={() => props.history.push("/")}>
            <Icon name="close" />
          </Button>
        </div>
        <div className="aboutus-title">
          <h2>About</h2>
          <h1>Privacy Policy</h1>
        </div>
        <div className="aboutus-content">
          <div className="content-block">
            <h2>Controller</h2>
            <p>
              Frinx s.r.o., having its offices at Mlynské nivy 48, 821 09
              Bratislava, Slovakia
            </p>
          </div>
          <div className="content-block">
            <h2>Data we collect</h2>
            <p>We collect username (email) and password of users.</p>
          </div>
          <div className="content-block">
            <h2>What we use it for</h2>
            <p>
              We use the username(email) and password to enable customers to log
              into our webpage and retrieve information about their license
              token and license status. The license token enables users to
              activate our software for evaluation and commercial purposes. From
              time to time, we also send out newsletters to users who have
              opted-in for our newsletter. You can unsubscribe at any time –
              either by clicking the unsubscribe link in the footer of any email
              or by contacting us at legal@elisapolystar.com if you require any further
              assistance.
            </p>
          </div>
          <div className="content-block">
            <h2>Legal bases</h2>
            <p>
              We collect and process information only where we have legal bases
              for doing so under applicable EU laws. This means we collect and
              use your information only where:{" "}
            </p>
            <p>
              <ul>
                <li>
                  you have consented to our use of information about you for a
                  specific purpose
                  <ul>
                    <li>
                      please note that you have the right to change your mind at
                      any time, but this will not affect any processing that has
                      already taken place
                    </li>
                  </ul>
                </li>
                <li>
                  it satisfies our legitimate interest(except where our
                  interests are overridden by your interests), such as to market
                  and promote our services and products
                  <ul>
                    <li>
                      if we are using your information because we have a
                      legitimate interest to do so, you have the right to object
                      to that use at any time
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
          </div>
          <div className="content-block">
            <h2>Recipients</h2>
            <p>We do not provide your data to any recipients.</p>
          </div>
          <div className="content-block">
            <h2>Transfer of personal data to third countries</h2>
            <p>We process your data only in EU countries.</p>
          </div>
          <div className="content-block">
            <h2>How long we keep information</h2>
            <p>
              We retain your account information (email and password) for as
              long as your account is active. We may also retain some
              information as necessary to comply with our legal obligations, to
              resolve disputes, to enforce our agreements, and to support
              business operations.
            </p>
          </div>
          <div className="content-block">
            <h2>How to access and control your information</h2>
            <p>
              You have certain choices available to you when it comes to your
              information. Below is a summary of those choices, how to exercise
              them and any limitations. You have the right to request a copy of
              your information, to object to our use of your information
              (including for marketing purposes), to request the deletion or
              restriction of your information, or to request your information in
              a structured, electronic format. You may contact us as provided in
              the Contact Us section below to request assistance.
            </p>
            <p>
              Access and update your information. You can access and update your
              information by contacting us at legal@elisapolystar.com.
            </p>
            <p>
              Delete your account. If you wish to delete your account and
              information we have about you, please contact legal@elisapolystar.com.
            </p>
            <p>
              Please note, however, that we may need to retain certain
              information for record keeping purposes, to complete transactions
              or to comply with our legal obligations.
            </p>
            <p>
              Request that we stop using your information. In some cases, you
              may ask us to stop accessing, storing, using and otherwise
              processing your information where you believe we don’t have the
              appropriate rights to do so.{" "}
              <strong>
                Where you gave us consent to use your information for a limited
                purpose, you can contact us to withdraw that consent, but this
                will not affect any processing that has already taken place at
                the time. You can also opt-out of our use of your information
                for marketing purposes by contacting us, as provided below.
              </strong>
            </p>
            <p>
              Opt out of marketing communications. You may opt out of receiving
              marketing communications from us by using the unsubscribe link
              within each email, or by contacting us as provided below to have
              your contact information removed from our promotional email list
              or registration database.
            </p>
            <p>
              Data portability. Data portability is the ability to obtain some
              of your information in a format you c an move from one service
              provider to another. Depending on the context, this applies to
              some of your information, but not to all of your information.
              Should you request it, we will provide you with an electronic file
              of your basic account information.
            </p>
            <p>
              Right to lodge a complaint with a supervisory authority. You have
              a right to lodge a complaint by Office for personal data
              protection of the Slovak Republic
              (https://dataprotection.gov.sk/uoou/en).
            </p>
          </div>
        </div>
      </div>
      <div className="aboutus-bg" />
    </div>
  );
}

export default PrivacyPolicy;
