import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Grid, Radio } from "semantic-ui-react";
import { MobileContext } from "../common/MobileProvider";
import fire from "../config/Fire";
import { AuthContext } from "./AuthProvider";

function Profile({ history }) {
  const [isSubscribe, setSubscribe] = useState(true);
  const [email, setEmail] = useState();
  const { currentUser } = useContext(AuthContext);
  const [license, setLicense] = useState();
  const { isMobile } = useContext(MobileContext);

  useEffect(() => {
    const email = currentUser.email;
    const creationTime = currentUser.metadata.creationTime;
    axios
      .post("/license/" + email, {
        creationTime: creationTime,
      })
      .then((res) => {
        let licenseData = res.data;
        setLicense(licenseData);
        if (res.data.licenseType.name === "TRIAL") {
          axios.put("/trial-license", { email }).then((res) => {
            setLicense({
              ...licenseData,
              licenses: [{ token: res.data.token }],
            });
          });
        }
      });

    axios
      .post("/user/preferences", {
        email,
        creationTime: creationTime,
      })
      .then((res) => {
        setSubscribe(res.data.newsletter);
      });
  }, []);

  const setPreferences = (preferences) => {
    axios
      .put("/user/preferences", {
        email: currentUser.email,
        creationTime: currentUser.metadata.creationTime,
        preferences,
      })
      .then((res) => {
        setSubscribe(res.data.newsletter);
      });
  };

  return (
    <div className="auth-wrapper">
      <div className="home-page profile-wrapper">
        <div className="profile-title">
          <h1>User Account</h1>
          <h2>{currentUser?.email}</h2>
        </div>
        <div className="profile-content">
          <div className="license-info">
            <div className="token">
              <h2>My secret token</h2>
              <pre>{license?.licenses[0]?.token}</pre>
            </div>
            <h2>My license information</h2>
            <div className="info">
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column className="info-type">
                    <p>
                      License Type: <span>{license?.licenseType?.name}</span>
                    </p>
                    <p>
                      Licenses of registered devices:{" "}
                      <span>{license?.licenses?.length}</span>
                    </p>
                    <p>
                      Number of Licenses left:{" "}
                      <span>1 out of {license?.licenseType?.maxCount}</span>
                    </p>
                  </Grid.Column>
                  <Grid.Column className="info-type">
                    <p>
                      Created Date: <span>{license?.licenses[0]?.created}</span>
                    </p>
                    <p>
                      Expiry Date:{" "}
                      <span>{license?.licenses[0]?.expiryDate}</span>
                    </p>
                    <p>
                      Hostname:{" "}
                      <span>{license?.licenses[0]?.fingerprint?.hostname}</span>
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
          <div className="subscribe">
            <h2>Newsletter</h2>
            <p>
              Occasionally we'd like to send you our newsletter. Please indicate
              below whether this is okay. You can change your mind at any time
              by clicking the "Unsubscribed" radio button.
            </p>
            <Radio
              label="Subscribed"
              onChange={() => setPreferences({ newsletter: true })}
              name="radioGroup"
              value="sub"
              checked={isSubscribe}
              style={{ marginRight: "30px" }}
            />
            <Radio
              label="Unsubscribed"
              onChange={() => setPreferences({ newsletter: false })}
              name="radioGroup"
              value="unsub"
              checked={!isSubscribe}
            />
          </div>
          <div className="settings">
            <p>
              <a onClick={() => history.push("/reset-password")}>
                > Reset password
              </a>
            </p>
            <p>
            <a onClick={() => history.push("/eula")}>
                 > EULA
                </a>
            </p>
            <p>
              <a onClick={() => fire.auth().signOut()}>> Logout</a>
            </p>
          </div>
        </div>
      </div>
      <div className="auth-bg" />
    </div>
  );
}

export default withRouter(Profile);
