import React from "react";
import { Button, Icon } from "semantic-ui-react";
import usecases from "../images/solution/usecases.png";
import infrastructure from "../images/solution/infrastructure.png";
import implementation from "../images/solution/implementation.png";
import controller from "../images/solution/controller.png";
import scalable from "../images/solution/scalable.png";
import workflow_manager from "../images/solution/workflow-manager.png";
import scalable2 from "../images/solution/scalable2.png";
import resource_manager1 from "../images/solution/resource-manager1.png";
import resource_manager2 from "../images/solution/resource-manager2.png";

function Solution(props) {
  return (
    <div className="solution-wrapper">
      <div className="solution-content-wrapper">
        <div className="solution-title">
          <h1>FRINX Products and Solution</h1>
        </div>
        <div className="solution-content">
          <div className="content-block">
            <h2>FRINX Machine Use Cases</h2>
            <div>
            <img src={usecases} width="60%" alt="img"/>
            <p className="imagep"> FRINX Machine Use Cases </p>
            </div>
            <p>
             FRINX Machine automates the discovery and configuration of network services and devices. 
             By combining the ease and flexibility of workflows and the power of YANG models, operators use FRINX Machine to provision and reconcile services like network slices and VPNs, 
             automate lifecycle operations in their network and get full access to operational network data. Simple and complex processes are automated by designing workflows and the ability 
             to add or modify tasks at runtime programmatically and via APIs. By including Machine Learning as a trigger for workflow execution, FRINX Machine provides closed loop automation 
             for large scale communication service provider networks.
            </p>
          </div>
          <div className="content-block">
            <h2>FRINX Machine Infrastructure Automation Solution</h2>
            <div>
            <img src={infrastructure} width="60%" alt="img"/>
            <p className="imagep"> Infrastructure & Service automation </p>
            </div>
            <p>FRINX Machine consists of three main components and supporting services that are delivered in cloud native format. 
            Customers can choose to deploy on premise and in the cloud via docker swarm or Kubernetes</p>
            <div>
            <img src={implementation} width="60%" alt="img"/>
            <p className="imagep"> Implementation & Deployment</p>
            </div>
            <p>UniConfig provides cloud native, state-less and horizontally scalable network control with support for YANG models, 
            an open-source device library and state of the art network control features. Workflow Manager provides cloud native, state-less and horizontally scalable workflow 
            design and execution capabilities. Workflows can be created via the workflow builder UI and leverage extensible micro services that ship with FRINX Machine 
            to achieve process automation goals. Finally, Resource Manager provides a resource manager to allocate network consumables like IP addresses, route distinguishers, 
            VLANs and more. The integration of these components and the supporting services save our customers many person years of integration and testing work, so that 
            they can focus on automating their processes and business logic.</p>
          </div>
          <div className="content-block">
            <h2>UniConfig</h2>
            <div>
            <img src={controller} width="60%" alt="img"/>
            <p className="imagep"> UniConfig network controller</p>
            </div>
            <p>The purpose of FRINX UniConfig is to manage the intent or the desired configuration of services, physical and virtual networking devices through a 
            single well defined network API. In addition, UniConfig enables device and network wide transactions with rollback and snapshots so that the network will 
            always remain in a well-defined state without leftovers from failed configuration attempts. UniConfig leverages an open-source device library that translates 
            between thousands of vendor network device types and models and OpenConfig. UniConfig can be deployed standalone or as part of FRINX Machine.</p>
            <div>
            <img src={scalable} width="60%" alt="img"/>
            <p className="imagep"> Horizontally scalable network control</p>
            </div>
            <Button
              primary
              onClick={() => window.open("https://docs.frinx.io/frinx-uniconfig/getting-started/", "_blank")}
              className="solution-button"
            >
              Read more about UniConfig here
            </Button>
          </div>
          <div className="content-block">
            <h2>Workflow Manager</h2>
            <div>
            <img src={workflow_manager} width="60%" alt="img"/>
            <p className="imagep"> Workflow Manager workflow design and execution</p>
            </div>
            <p>FRINX Workflow Manager enables customers to create automated, repeatable, digital processes to build, grow and operate their digital communication infrastructure. 
            FRINX Workflow Manager is based on open-source components and enables infrastructure and network engineers to create and operate workflows to implement configuration 
            changes and obtain operational data from their heterogeneous networks and clouds. Typical examples are the automation of services that span resources in the 
            cloud and physical assets, the automation of slices and capacity increases in mobile networks, the interaction with CRM and inventory systems, the management 
            of Internet and Infrastructure services and the automation of core network functions. Workflow Manager can be deployed standalone or as part of FRINX Machine.</p>
            <div>
            <img src={scalable2} width="60%" alt="img"/>
            <p className="imagep"> Horizontally scalable workflow execution</p>
            </div>
            <Button
              primary
              onClick={() => window.open("https://docs.frinx.io/frinx-workflow-manager/introduction/", "_blank")}
              className="solution-button"
            >
              Read more about Workflow Manager here
            </Button>
          </div>
          <div className="content-block">
            <h2>Resource Manager</h2>
            <div>
            <img src={resource_manager1} width="60%" alt="img"/>
            <p className="imagep"> Resource Manager -  Inventory and resource management</p>
            </div>
            <p>FRINX Resource Manager was developed for network operators and infrastructure engineers to manage their physical and logical assets and resources. 
            Examples for assets are locations, equipment, ports and services. Examples for resources are IP addresses, VLAN IDs and other consumables required 
            for operating data services. Resource Manager was developed specifically to address the needs of network and infrastructure engineers working with communication 
            networks.FRINX Resource Manager provides GUI and a GraphQL based API to create, read, update and delete assets. Resource Manager can be deployed standalone or as 
            part of FRINX Machine.</p>
            <div>
            <img src={resource_manager2} width="60%" alt="img"/>
            <p className="imagep"> Resource Manager - Horizontal scalability</p>
            </div>
            <Button
              primary
              onClick={() => window.open("https://docs.frinx.io/frinx-resource-manager/introduction/", "_blank")}
              className="solution-button"
            >
              Read more about Resource Manager here
            </Button>
          </div>
        </div>
      </div>
      <div className="solution-bg" />
    </div>
  );
}

export default Solution;
