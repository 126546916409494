import React, { useState } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { perks, positions } from "../content/careers";

function Careers() {
  const [showDetails, setShowDetails] = useState({
    show: false,
    which: null,
    title: "",
  });

  const positionTable = (positions) => (
    <Table>
      <Table.Header className="table-header">
        <Table.Row>
          <Table.HeaderCell>
            {" "}
            <Icon name="user" />
            Position
          </Table.HeaderCell>
          <Table.HeaderCell>
            {" "}
            <Icon name="map marker" />
            Location
          </Table.HeaderCell>
          <Table.HeaderCell>
            {" "}
            <Icon name="eur" />
            Orientational Salary
          </Table.HeaderCell>
          <Table.HeaderCell>
            {" "}
            <Icon name="time" />
            Contract
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Icon name="code" />
            Experience
          </Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {positions.map((p, i) => {
          return (
            <Table.Row>
              <Table.Cell>
                <h4 style={{ color: "#2185d0" }}>{p.title}</h4>
              </Table.Cell>
              <Table.Cell> {p.location}</Table.Cell>
              <Table.Cell>{p.salary}</Table.Cell>
              <Table.Cell>{p.time}</Table.Cell>
              <Table.Cell>{p.exp}</Table.Cell>
              <Table.Cell>
                <Button
                  circular
                  primary
                  onClick={() =>
                    setShowDetails({ show: true, which: i, title: p.title })
                  }
                >
                  Show
                </Button>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );

  const careerDetails = (i) => (
    <div className="careers-details">
      <Button circular onClick={() => setShowDetails({ show: false })}>
        Back to list
      </Button>
      <div className="careers-close">
      	<Icon name="times circle" onClick={() => setShowDetails({ show: false })}/>
      </div>
      <h2>{showDetails.title}</h2>
      <h3>
        <Icon name="info circle" />
        Job Description
      </h3>
      <div className="careers-details-list">
        {positions[i].jobDesc.map((jd) => {
          return <p> > {jd}</p>;
        })}
      </div>
      <h3>
        <Icon name="plus circle" />
        Employee perks and benefits
      </h3>
      <div className="careers-details-list">
        {perks.map((pk) => {
          return <p> > {pk}</p>;
        })}
      </div>
      <h3>
        <Icon name="box" />
        Job Requirements
      </h3>
      <div className="careers-details-list">
        <h4>Education</h4>
        {positions[i].jobReq.education.map((e) => (
          <p> > {e}</p>
        ))}
        <h4>Personal requirements and skills</h4>
        {positions[i].jobReq.personal.map((p) => (
          <p> > {p}</p>
        ))}
        <h4>Language skills</h4>
        {positions[i].jobReq.lang.map((l) => (
          <p> > {l}</p>
        ))}
      </div>
  <div style={{"textAlign": "center"}}>
  <p>
    <a href="mailto:career@frinx.io"><h3>Interested? <u>Send us your CV!</u></h3></a>
  </p>
</div>
      {/* <Divider />
      <div style={{ width: "500px" }}>
        <h3>Interested?</h3>
        <p>Send us your email address and we will reach out to you.</p>
        <Input
          action={{
            color: "teal",
            labelPosition: "right",
            icon: "send",
            content: "Apply",
          }}
          fluid
          placeholder="Your email address"
          style={{ marginTop: "30px" }}
        />
      </div> */}
    </div>
  );
  return (
    <div className="careers-wrapper">
      <div className="careers-content-wrapper">
        <div className="careers-title">
          <h2>Careers at FRINX</h2>
          <h1>Open positions</h1>
        </div>
        <div className="careers-content">
          {showDetails.show
            ? careerDetails(showDetails.which)
            : positionTable(positions)}
        </div>
      </div>
      <div className="careers-bg" />
    </div>
  );
}

export default Careers;
