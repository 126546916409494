import React from "react";
import { Button, Icon } from "semantic-ui-react";
import waves from "../images/b2b/3_waves_of_automation.png";

function b2b(props) {
  return (
    <div className="cable-wrapper">
      <div className="cable-content-wrapper">
        <div className="cable-title">
          <h1>The 3rd wave of business service automation</h1>
        </div>
        <div className="cable-content">
          <div className="content-block">
            <div>
            </div>
            <p>
             Automating business to business networking services is critical to meet customer expectations related to how quickly their data and 
             communication services are established and how quickly and easily they can be changed and updated. Business service automation 
             involves various IT systems and the network. FRINX works with brownfield as well as greenfield networks and with many different 
             networking vendors to automate services like EVPN, network slices, L3 and L2 VPNs for our customers. We have come a long way in 
             automating our critical communication infrastructure as we are entering the 3rd wave of network service automation together with 
             our customers. {"\n"}
            </p>
            <p>
             The first wave of network automation in the 90's and 00's was focused on scripts and languages like TCL/Expect to 
             interact with shells of network devices. The lack of abstraction between devices and service intent made it difficult to maintain 
             these scripts and any device software upgrade had a potentially severe impact on the logic of these scripts. Many modules and 
             libraries exist in Perl or Ansible that make it simpler to interact with network devices, but the underlying issue of imperative 
             automation (a step-by-step device specific instruction sequence) has remained the same. These systems are labor intensive to build and 
             maintain and require operators to repeatedly build and solve basic layers of functionality.
            </p>
            <p>
             In a second wave of automation tools, networking vendors have acquired or built software that uses network models like YANG and 
             protocols like NETCONF. A lot of progress was made, and these tools were able to load the current configuration state of devices 
             and perform atomic changes. Also, the ability to create service models and map them to device models improved the automation capacity 
             of operators of large-scale networks. Although a lot of progress was made in the second wave of automation, several limitations remained. 
             The tools were mostly proprietary and hard to program. Scale remained somewhat limited due to the monolithic (pre-cloud) architecture 
             and development was limited to a small group of certified partners. 
            </p>
            <p>
             FRINX is part of a third wave of network automation solutions that solve device control at high scale and that provide workflow 
             and resource management functions all based on open-source software. We use standardized models like OpenConfig and IETF and we 
             leverage protocols like gNMI, Netconf and a vast open-source device library for backwards compatibility with legacy devices. 
             We deploy our solution on-premise and in the cloud. By enabling our customers to focus on creating and evolving their business 
             logic we help them to spend time on the portion of the automation code that differentiates them and creates real value for them. 
             FRINX is delivering the third wave of automation with a solution that allows customers to focus on value creation, user experience 
             and service enablement instead of dealing with automation infrastructure. 
            </p>
            <br></br>
            <center><img src={waves} width="80%" alt="img"/></center>
          </div>
        </div>
      </div>
      <div className="cable-bg" />
    </div>
  );
}

export default b2b;
