import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import TweenOne from "rc-tween-one";
import React from "react";
import { Button, Grid, Image } from "semantic-ui-react";
import tryit from "../images/tiles/tryit.svg";
import started from "../images/tiles/started.svg";
import download from "../images/tiles/download.svg";
import guides from "../images/tiles/guides.svg";
import videos from "../images/tiles/videos.svg";
import docs from "../images/tiles/docs.svg";

const orientationContent = [
  {
    title: "Getting started",
    text:
      "Learn about FRINX products and solutions and the benefits of infrastrucure automation.",
    icon: started,
    link: "https://frinx.io/solution"
  },
  {
    title: "Download and install",
    text:
      "Install and explore FRINX Machine in your own environment.",
    icon: download,
    link: "https://github.com/FRINXio/FRINX-machine"
  },
  {
    title: "Guides & Use Cases",
    text:
      "Find a step-by-step description of the demo use cases that ship with FRINX Machine.",
    icon: guides,
    link: "https://docs.frinx.io/frinx-machine/use-cases/"
  },
  {
    title: "Popular videos",
    text:
      "Find a selection of videos demonstrating the capabilities of FRINX products and solutions.",
    icon: videos,
    link: "https://www.youtube.com/watch?v=n0SBR2UI0eg&list=PLQ2GwwrvIDrE90Ywa_A5SdQ2t4XRUF8w7"
  },
  {
    title: "Documentation",
    text:
      "Browse through our technical documentation and learn about how FRINX products work.",
    icon: docs,
    link: "https://docs.frinx.io/"
  },
  {
    title: "Try it out",
    text:
      "Try out our cloud-based installation of FRINX Machine. Note this is a public instance hosted for demo purposes only.",
    icon: tryit,
    link: "https://demo.frinx.io/"
  }
];

function Orientation() {
  return (
    <div className="home-page orientation-wrapper">
      <div className="orientation-title">
      </div>
      <OverPack playScale={0.3}>
        <Grid justifyContent="center" alignItems="center"
      justify="center">
          <Grid.Row columns={3}>
            {orientationContent.map((orientation, i) => (
              <Grid.Column computer={5} mobile={16}>
                <TweenOne
                  animation={{
                    y: "+=30",
                    opacity: 0,
                    type: "from",
                    ease: "easeOutQuad",
                    delay: 200 + 100 * i
                  }}
                >
                  <div className="orientation-block">
                    <img className="orientation-block-icon" src={orientation.icon} />

                    <div className="orientation-block-content">
                      <div className="orientation-block-content-title">
                        <h3>{orientation.title}</h3>
                      </div>
                      <p>{orientation.text}</p>
                      <Button
                      color="blue"
                      className="orientation-button"
                      onClick={() =>
                        window.open(
                          orientation.link,
                          "_blank"
                        )
                      }
                    >
                      {orientation.title}
                    </Button>
                    </div>
                  </div>
                </TweenOne>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </OverPack>
    </div>
  );
}

export default Orientation;
