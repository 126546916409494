import React from "react";
import { Button, Icon } from "semantic-ui-react";

function ContactUs(props) {
  return (
    <div className="aboutus-wrapper">
      <div className="aboutus-content-wrapper">
        <div style={{ textAlign: "right" }}>
          <Button circular icon onClick={() => props.history.push("/")}>
            <Icon name="close" />
          </Button>
        </div>
        <div className="aboutus-title">
          <h2>About</h2>
          <h1>Contact Us</h1>
        </div>
        <div className="aboutus-content">
          <div className="content-block">
            <h2>Inquiries about FRINX products and services</h2>
            <p>
              Email: marketing@elisapolystar.com
              <br></br>
              <button type="button" className="aboutus-button">
                <a href="mailto:marketing@elisapolystar.com">
                  <h3>Contact now</h3>
                </a>
              </button>
            </p>
          </div>
          <div className="content-block">
            <h2>Human resources/Careers</h2>
            <p>
              Email: career@frinx.io
              <br></br>
              <button type="button" className="aboutus-button">
                <a href="mailto:career@frinx.io">
                  <h3>Contact now</h3>
                </a>
              </button>
            </p>
          </div>
          <div className="content-block">
            <h2>Contact Address:</h2>
            <p>
              Frinx s.r.o. Mlynské nivy 4959/48, 821 09 Bratislava, Slovakia <br/>
              <br/>
              Tel #1: +421 2 209 101 41 <br/>
              Tel #2: +421 911 392 964 <br/>
            </p>
          </div>
        </div>
      </div>
      <div className="aboutus-bg" />
    </div>
  );
}

export default ContactUs;
