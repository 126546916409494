import React from "react";
import { Button, Icon } from "semantic-ui-react";
import cable1 from "../images/cable/cable_1.png";
import cable2 from "../images/cable/cable.gif";

function Cable(props) {
  return (
    <div className="cable-wrapper">
      <div className="cable-content-wrapper">
        <div className="cable-title">
          <h1>Cable license automation</h1>
        </div>
        <div className="cable-content">
          <div className="content-block">
            <div>
            <center><img src={cable1} width="60%" alt="img"/></center>
            <br></br>
            </div>
            <p>
             Automation is the driving force behind productivity gains for most industries. FRINX has created automation solutions that 
             increase productivity for communication service provider across all access and core technologies. 
             FRINX solutions help our customers to leverage workflow, resource management and network control capabilities to solve for a 
             broad number of use cases. One of those use cases is our closed loop license management for cable operators. 
             Most of the CAPEX spend for Cable operators is in the access networks, an important part of that recurring spend are DOCSIS CMTS Capacity licenses. 
             Cable Operators need to buy capacity licenses based on peak usage, by analyzing subscriber behavior, significant cost can be saved by applying 
             capacity licenses where and when they are needed. As operators start to deploy DOCSIS 3.1, optimizing license CAPEX spend becomes even more important. 
             FRINX has developed a solution using FRINX Machine to automate that optimization process. Leverage FRINX Machine to reduce your CMTS CAPEX license 
             spend with 10% to 20%. Enable continuously monitoring of subscriber behavior to optimize the license allocations on your CMTS installed base, use 
             FRINX Machine to orchestrate your CMTS systems.
            </p>
            <center><img src={cable2} width="80%" alt="img"/></center>
          </div>
        </div>
      </div>
      <div className="cable-bg" />
    </div>
  );
}

export default Cable;
