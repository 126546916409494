export const perks = [
  "Work on newest projects for global telecommunications companies - what we do cannot be learnt from text books",
  "Work in an international, dynamic and fast-growing company",
  "Young, agile and friendly team",
  "Casual dress code",
  "We believe in personal and professional growth",
];

export const positions = [
  {
    title: "SENIOR PYTHON SOFTWARE ENGINEER",
    location: "Bratislava or Poprad office",
    salary: "1800€ - 3500€",
    time: "Full-time, Options of home-office",
    exp: "Python",
    jobDesc: [
      "Analysis and architectural design for implementation in our products in customer environment",
      "Implementation of unit and regression tests using Python3",
      "Taking pride in writing clean, optimized code (some of it is open sourced)",
      "Reviewing of code developed by other colleagues",
      "Estimating small to large tasks and oversee the completion",
      "Development of technical documentation",
    ],
    jobReq: {
      education: [
        "Secondary with school-leaving examination",
        "Follow-up/Higher Professional Education",
        "University education (Bachelor’s degree)",
        "University education (Master’s degree)",
        "Education Specialization in IT",
      ],
      personal: [
        "Advanced knowledge of Python (min 2 year)",
        "The ability to handle a full life cycle development from analysis, design, implementation and testing",
        "Required knowledge of GIT",
        "Basic knowledge of Unix and Bash",
      ],
      lang: ["English – Upper intermediate (B2)"],
    },
  },
  {
    title: "FRONTEND DEVELOPMENT TEAM LEAD",
    location: "Bratislava or Poprad office",
    salary: "2500€ - 3500€",
    time: "Full-time, Options of home-office",
    exp: "React, JavaScript, GIT, TypeScript, HTML, CSS",
    jobDesc: [
      "Lead and manage the front-end team in a hybrid setting.",
      "Lead technical architecture, coding standards, and other team policies.",
      "Manage the front-end tasks' board.",
      "Take conceptual product and design ideas and turn them into valuable and delightful experiences for our users.",
      "Work closely with Product Managers and Product Designers to implement best-in-class user experiences.",
      "Work closely with Back-end Developers to create a well-implemented product.",
    ],
    jobReq: {
      education: [
        "Secondary with school-leaving examination",
        "Follow-up/Higher Professional Education",
        "University education (Bachelor’s degree)",
        "University education (Master’s degree)",
        "Education Specialization in IT",
      ],
      personal: [
        "5+ years of React.js + JavaScript experience, including concepts like asynchronous programming, closures, types, and ES6.",
        "5+ years of HTML/CSS experience, including concepts like layout, specificity, cross-browser compatibility, and accessibility.",
        "Familiarity with RESTful and GraphQL APIs.",
        "Experience with Git, Jira, & browser-based debugging and performance testing software.",
        "Good understanding of developing web-based design.",
        "Strong leadership & communication skills.",
        "Experience with Prisma and Nexus.js on backend (nice to have).",
      ],
      lang: ["English – Upper intermediate (B2)"],
    },
  },
];
