import React from "react";
import { Button, Icon } from "semantic-ui-react";

function Company(props) {
	return (
		<div className="aboutus-wrapper">
			<div className="aboutus-content-wrapper">
				<div style={{ textAlign: "right" }}>
					<Button
						circular
						icon
						onClick={() => props.history.push("/")}
					>
						<Icon name="close" />
					</Button>
				</div>
				<div className="aboutus-title">
					<h2>About</h2>
					<h1>Company</h1>
				</div>
				<div className="aboutus-content">
					<div className="content-block">
						<h2>WHO IS FRINX</h2>
						<p>
							FRINX builds software that enables customers to
							create automated, repeatable, digital processes to
							build, grow and operate their digital
							infrastructure. Enterprise and service provider
							customers are choosing FRINX products and solutions
							to automate cloud assets, branch offices, core, edge
							and access networks. The goal of automation is to
							provide programmatic interfaces to customers
							(internal or external), to save time and resources
							in deploying infrastructure changes and to provide a
							cost effective basis for adding new functionality in
							the infrastructure. FRINX provides software that
							enables low-code workflow design and operation,
							analytics to support machine learning and intent
							based infrastructure control to integrate devices
							and services from many networking vendors. FRINX
							solutions are operated and deployed by industry
							leaders like Facebook, SoftBank, Vodafone and other
							Global Fortune 500 companies to support their
							automation needs. FRINX has offices in Bratislava, 
							Slovakia and New York, NY. The company is part of 
							Elisa Polystar.
						</p>
					</div>
					<div className="content-block">
						<h2>WHY SHOULD I CARE?</h2>
						<p>
							We haven’t seen anything yet when it comes to the
							acceleration of our world. More people will
							participate in this virtuous circle by lowering the
							cost of connectivity and their ability to contribute
							and create value. The move to an economy that is
							experience-based and fueled by virtual goods is the
							evolution from today’s carbon economy. This new
							economy is based on data communication at its core
							and no longer on oil. We want to play a part in
							lowering the cost of access and the cost of service
							to enable more people to participate. Our mantra is
							the automation of workflows, the use of analytics
							and machine learning and the abstraction of
							heterogeneous physical assets.
						</p>
					</div>
					<div className="content-block">
						<h2>WHAT PROBLEMS CAN FRINX HELP ME SOLVE?</h2>
						<p>
							At FRINX, we use our energy to build the factory to
							provide communication services. Our source materials
							are fiber, switches, routers, radio network
							controllers and all the blinking boxes that make up
							the physical infrastructure that makes up a network.
							The output of our factory are services that create
							connectivity between branches and campuses, between
							individuals and services and between corporations
							and their customers. We provide the tooling as well
							as the processes to build, operate and grow those
							services. We establish Metro Ethernet services to
							cell towers, we enable the provisioning of EVPN
							services for large corporations and we automate the
							worlds largest core networks.
						</p>
					</div>
				</div>
			</div>
			<div className="aboutus-bg" />
		</div>
	);
}

export default Company;